import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import { Drawer } from "@reifyhealth/picasso-pkg";

export type DrawerState = {
  activeContentId: string | null;
  closable: boolean;
  footer: ReactNode;
  isVisible: boolean;
  title: ReactNode;
  width: string | number | undefined;
};

type DrawerContextProviderProps = {
  openDrawer: (drawerState: Partial<DrawerState>) => void;
  updateDrawerState: (drawerState: Partial<DrawerState>) => void;
  closeDrawer: () => void;
  resetDrawer: () => void;
  state: DrawerState;
};

const DrawerContext = createContext<DrawerContextProviderProps | undefined>(
  undefined,
);

export const DrawerContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialDrawerState: DrawerState = {
    isVisible: false,
    title: null,
    closable: true,
    activeContentId: null,
    footer: null,
    width: "700px",
  };

  const [drawerState, setDrawerState] =
    useState<DrawerState>(initialDrawerState);

  const openDrawer = (newValues: Partial<DrawerState>) => {
    setDrawerState((currentState) => ({
      ...currentState,
      ...newValues,
      isVisible: true,
    }));
  };

  const updateDrawerState = (newValues: Partial<DrawerState>) => {
    setDrawerState((currentState) => ({ ...currentState, ...newValues }));
  };

  const closeDrawer = () => {
    setDrawerState((currentState) => ({ ...currentState, isVisible: false }));
  };

  const resetDrawer = () => {
    setDrawerState(initialDrawerState);
  };

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        updateDrawerState,
        closeDrawer,
        resetDrawer,
        state: drawerState,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = (): DrawerContextProviderProps => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerContextProvider");
  }
  return context;
};

type DrawerChildProps = {
  id: string;
  [key: string]: any;
};

type DrawerContainerProps = {
  children: ReactNode;
  onDrawerCloseHandlers?: { [key: string]: () => void };
};

const DrawerContainer: React.FC<DrawerContainerProps> = ({
  children,
  onDrawerCloseHandlers = {},
}) => {
  const { state, closeDrawer: defaultCloseHandler, resetDrawer } = useDrawer();

  const drawerCloseHandler =
    state.activeContentId && onDrawerCloseHandlers[state.activeContentId]
      ? onDrawerCloseHandlers[state.activeContentId]
      : defaultCloseHandler;

  useEffect(() => {
    resetDrawer();
  }, []);

  const activeContent = React.Children.toArray(children).find((child) => {
    if (React.isValidElement<Pick<DrawerChildProps, "id">>(child)) {
      return child.props.id === state.activeContentId;
    }
    return false;
  });

  return (
    <Drawer
      title={state.title}
      placement="right"
      bodyStyle={{ padding: "0px" }}
      closable={state.closable}
      keyboard={state.closable}
      maskClosable={state.closable}
      onClose={drawerCloseHandler}
      visible={state.isVisible}
      width={state.width}
      style={{ top: "56px", bottom: 0, height: "unset" }}
      footer={state.footer}
    >
      {activeContent}
    </Drawer>
  );
};

export default DrawerContainer;
